<template>
  <base-card>
    <template #header>يرجى الإنتباه</template>
    <template #body>
      <h6>
        شكرا لتسجيلك بموقع جو مزاد, حتى تتمكن من المزاودة يتوجب عليك زيارة الشركة و ايداع مبلغ التأمين
        الخاص بك
      </h6>
    </template>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";

export default {
  components: {BaseCard}
}
</script>